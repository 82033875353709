import React, { useState, useRef, useLayoutEffect } from 'react';
import { useLocation } from "react-router-dom";

import { Grid, Box, Typography, Divider, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import { Link } from "react-router-dom";
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

import { ParallaxBanner, Parallax } from 'react-scroll-parallax';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import Letter from './Letter';
import Footer from './Footer';
import Cards from './Cards';

import InfoIcon from '@mui/icons-material/Info';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
const Home = () => {
    const { t } = useTranslation();

    const [currentCount, setCurrentCount] = useState(0)
    const incrementCounter = () => {
        setCurrentCount(currentCount + 1);
    }

    const scrollToComment = () => {
        let currentLocation = window.location.href;
        const hasCommentAnchor = currentLocation.includes("/#");
        console.log(currentLocation, hasCommentAnchor);
        if (hasCommentAnchor) {
            const anchorCommentId = `${currentLocation.substring(currentLocation.indexOf("#") + 1)}`;
            console.log(anchorCommentId);
            const anchorComment = document.getElementById(anchorCommentId);
            if (anchorComment) {
                anchorComment.scrollIntoView({ behavior: "smooth" });
            }
        }
    }

    const location = useLocation();


    useLayoutEffect(() => {
        console.log("location", location)
        scrollToComment();


    }, [location])





    return (<>

        <Box className="parallax-container" id="home">

            <Box className="parallax-layer-1">
                <Grid className="appear-1" container spacing={3} justifyContent="center" alignItems="center" direction="column" component={Box} mt={23} style={{ color: "white" }}>
                    <Grid item >
                        <Typography variant="h2" align="center">
                            <Box className="dark-shadow">
                                Anero P.S.A.
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box class="lawdivider-container">
                            <div class="lawdivider"></div>

                        </Box>
                    </Grid>

                    <Grid item container justifyContent="center">
                        <Grid item component={Typography} variant="h5" align="center"  >
                            <Box className="dark-shadow" sx={{backdropFilter: "blur(2px) brightness(80%)", padding: "5px", borderRadius: "5px"} }>
                                Twój pewny partner inwestycji
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <div class="chevron-container">
                            <div class="chevron"></div>
                            <div class="chevron"></div>
                            <div class="chevron"></div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box className="blur-section-borders" />
        <Box className="gradient-section" id="o-nas">
            <Box p={{ xs: 1, sm: 6, md: 12 }} sx={{ textAlign: 'center', color: "white" }}>
                <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={4} spacing={4}>
                    <Grid item>
                        <Typography variant="h4">
                            <Box >
                                O nas
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            <Box className="bodycustomtext" mt={5}>
                                Anero P.S.A. jest dynamicznie rozwijającą się firmą, działająco głównie w sektorze kolejowym. Zakres naszych usług to m. in.:

                            </Box>
                        </Typography>
                    </Grid>


                    <Cards />

                    <Grid item>
                        <Typography variant="body1">
                            <Box className="bodycustomtext" mt={5}>
                                Realizujemy roboty budowlane w całej Polsce, posiadamy odpowiednie zaplecze sprzętowe oraz doświadczoną kadrę pracowników.
                            </Box>
                        </Typography>
                    </Grid>
                    <Box id="realizacje" />
                    <Grid mt={16} xs={12} item container justifyContent="center" >
                        <Box className="translucent-container" style={{ width: "100%", zIndex: 0, minHeight: "332px", position: "relative" }}>
                            <Grid container alignItems="center" justifyContent="center">
                                <Box className="circle-avatar-container-sm">
                                    <Box  mt={0.5}>
                                        <EngineeringIcon sx={{ width: "60px", height: "60px;", color: "#1f1600", opacity: "0.8" }} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid mt={2} px={{ xs: 0, sm: 2, md: 4 }} className="white-shadow" container direction="column" alignItems="center" justifyContent="center" spacing={3}>
                                <Grid item>
                                        <Box w={1} className="bodycustomtextonplex white-shadow" sx={{ textAlign: "center", color: "#1f1600", textShadow: "2px -1px 12px rgba(255,255,255,1)" }}>
                                            <Typography variant="h4">
                                                <Box >
                                                    Realizacje
                                                </Box>
                                            </Typography>
                                        </Box>
                                </Grid>
                                <Grid item container >
                                    <Grid item xs={12}>
                                        <Box p={1}  >
                                            <Typography sx={{ color: "#1f1600", textAlign: "initial" }} variant="body1">
                                                <CheckCircleOutlineIcon/>
                                                Przebudowa peronu nr 1 na st. Gryfino - peron 346 mb dwukrawędziowy wraz z budową odwodnienia oraz fundamentami od wiaty sektorowej.
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box p={1}  >
                                            <Typography sx={{ color: "#1f1600", textAlign: "initial" }} variant="body1">
                                                <CheckCircleOutlineIcon />

                                                Budowa peronu nr 2 na p.o. Szczecin Żydowce - peron 130 mb jednokrawędziowy wraz z budową odwodnienia.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={1}  >
                                            <Typography sx={{ color: "#1f1600", textAlign: "initial" }} variant="body1">
                                                <CheckCircleOutlineIcon />

                                                Przebudowa przejazdu kolejowo - drogowego kat. A na ul. Transportowej w Szczecinie.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={1}  >
                                            <Typography sx={{ color: "#1f1600", textAlign: "initial" }} variant="body1">
                                                <CheckCircleOutlineIcon />

                                                Budowa przejścia podziemnego na p.o. Szczecin Dunikowo pod dwoma torami przy zastosowaniu konstrukcji odciążających.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={1}  >
                                            <Typography sx={{ color: "#1f1600", textAlign: "initial" }} variant="body1">
                                                <CheckCircleOutlineIcon />

                                                Budowa dwóch peronów 130 mb jednokrawędziowych na p.o. Szczecin Dunikowo wraz z nasypami oraz odwodnieniem.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={1}  >
                                            <Typography sx={{ color: "#1f1600", textAlign: "initial" }} variant="body1">
                                                <CheckCircleOutlineIcon />

                                                Ułożenie płyt peronowych oraz nawierzchni peronów nr 1 i 2 na p.o. Szczecin Łasztownia (perony na konstrukcji wsporczej ponad 4 m nad ziemią).
                                            </Typography>
                                        </Box>
                                    </Grid>
                                   


                                </Grid>

                               
                             
                                <Grid item className="bodycustomtextonplex">
                                    <Typography variant="body1">
                                        <Box component={Link} to="/gallery" sx={{ textAlign: "center", textDecoration: "underline" }}>
                                            {t("click_to_gallery")}
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item xs component={Link} to="/gallery">
                                    <Box mb={5} className="colage-preview">
                                    </Box>
                                </Grid>
                            </Grid>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box className="blur-section-borders" />
        <Box className="second-background" sx={{ overflowX: "hidden" }} >
            <Box mb={{ xs: 14, sm: 10, md: 8 }} />
            <Letter />
            <Box mb={4} />
        </Box>
        <Box className="blur-section-borders" />
        <Footer />
    </>
    );
}

export default Home
