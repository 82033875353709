import React from 'react';
import { Grid, Box, Typography, Divider, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Temida from '../assets/railway.webp';
import FamilyCourtImage from '../assets/experience.jpg';
import Court from '../assets/engineers.jpg';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const Cards = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Grid mt={5} item container alignItems="center" justifyContent="center" spacing={5} >
            <Grid item xs className="card-item-container" >
                <Box className="card-item" onClick={() => navigate("/#realizacje")}>
                    <Card sx={{ minWidth: 300, minHeight: 270 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={Court}
                                alt="Perony Kolejowe"
                            />
                            <CardContent>
                                
                                <Typography variant="body1">
                                    <Box className="bodycustomextonplex" sx={{ textAlign: "center" }}>
                                        Kompleksowa budowa oraz przebudowa peronów kolejowych w systemie L+P, L oraz P wraz z instalacjami, wiatami oraz elementami małej architektury.
                                    </Box>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs className="card-item-container">
                <Box className="card-item" onClick={() => navigate("/#realizacje")}>
                    <Card sx={{ minWidth: 300, minHeight: 270 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={FamilyCourtImage}
                                alt="Obiekty inżynieryjne"
                            />
                            <CardContent>
                                
                                <Typography variant="body1">
                                    <Box className="bodycustomtextonplex" sx={{ textAlign: "center" }}>
Budowa, przebudowa oraz remonty obiektów inżynieryjnych.                                     </Box>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs className="card-item-container" >
                <Box className="card-item" onClick={() => navigate("/#realizacje")}>
                    <Card sx={{ minWidth: 300, minHeight: 270 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={Temida}
                                alt="Układy torowe"
                            />
                            <CardContent>
                                
                                <Typography variant="body1">
                                    <Box className="bodycustomtextonplex" sx={{ textAlign: "center" }}>
                                        Budowa oraz przebudowa układów torowych w tym przejazdów kolejowo - drogowych. 
                                    </Box>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Cards
