import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import './custom.css';
import { ParallaxProvider } from 'react-scroll-parallax';
import ScrollToTop from './scrollToTop';
import "@fontsource/playfair-display"; // Defaults to weight 400
import "@fontsource/playfair-display/400.css"; // Specify weight
import "@fontsource/playfair-display/400-italic.css"; // Specify weight and style
// import i18n (needs to be bundled ;)) 
import './i18n';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";


const theme = createTheme({
    typography: {
        fontFamily: "Playfair Display"
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: "Playfair Display",
                    src: `@fontsource/playfair-display`
                },
                
            }
        }
    }
});


export default class App extends Component {
  static displayName = App.name;

  render() {
      return (
          <ThemeProvider theme={theme}>
              <CssBaseline />
        <Layout>
            <ParallaxProvider>
                <ScrollToTop/>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
                </Routes>
            </ParallaxProvider>

              </Layout>
          </ThemeProvider>
    );
  }
}
