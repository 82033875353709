import React, {  useRef, useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ArchitectureIcon from '@mui/icons-material/Architecture';
const Letter = () => {
    const { t } = useTranslation();
    const greekCircleRef = useRef();


    useEffect(() => {
        window.addEventListener('scroll', event => {
            requestAnimationFrame(() => {
                const rotation = window.scrollY / 15;
                greekCircleRef.current.style.transform = `rotate(${rotation}deg)`;
            });
        });
    }, []);
    return (
        <Box m={{ xs: 2, sm: 5, md: 13 }} p={{ xs: 4, sm: 6, md: 8 }} className="translucent-container">
            <Grid container alignItems="center" justifyContent="center">
                <Box className="circle-avatar-container-sm" sx={{marginTop: "-150px"} }>
                    <Box mt={0.5} ml={2.5 }>
                        <ArchitectureIcon sx={{ width: "60px", height: "60px;", color: "#1f1600", opacity: "0.8" }} />
                    </Box>
                </Box>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} >
                <Box className="side-image"></Box>
                </Grid>
                <Grid item xs={12} md={8} container className="white-shadow" container direction="column" spacing={2} alignItems="flex-start" justifyContent="center" >
                    <Grid item mt={{ xs: 4, sm: 2, md: 1 }} mb={6}>
                        <Typography variant="h4">

                            W trakcie realizacji
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box>
                            <KeyboardDoubleArrowRightIcon />Przebudowa peronu nr 1 na p.o. Szczecin Pomorzany
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box>
                            <KeyboardDoubleArrowRightIcon />Budowa peronów nr 1 i 2 na p.o. Szczecin Cmentarz Centralny

                        </Box>
                    </Grid>
                    <Grid item>
                        <Box>
                            <KeyboardDoubleArrowRightIcon />Przebudowa peronu nr 1 na p.o. Szczecin Pogodno wraz z remontem i przestawieniem zabytkowej wiaty żelbetowej

                        </Box>
                    </Grid>
                    <Grid item>
                        <Box>
                            <KeyboardDoubleArrowRightIcon />Przebudowa peronu nr 1 na p.o. Szczecin Gocław
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
           
           
        </Box>
    );
}

export default Letter
