import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Home from "./components/Home";


const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/contact',
        element: <Contact />
    },
    {
        path: '/gallery',
        element: <Gallery />
    },
];

export default AppRoutes;
