import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'

import { Grid, Divider } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import LanguageIcon from '@mui/icons-material/Language';
import HomeIcon from '@mui/icons-material/Home';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PaymentsIcon from '@mui/icons-material/Payments';
import WorkIcon from '@mui/icons-material/Work';
import NavMenuButtons from './NavMenuButtons';
import { Link } from "react-router-dom";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
const NavMenu = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [currentCount, setCurrentCount] = useState(0)
    //TODO rename this variable because it also applies to location
    const [topbarIsFullWidth, setTopbarIsFullWidth] = useState(false)

    const location = useLocation();



    const incrementCounter = () => {
        setCurrentCount(currentCount + 1);
    }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <>
            <Box className="topbar-fixed" style={{ zIndex: "1" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                   
                </Grid>
            </Box>
            <Box className="topbar-fixed" style={{ zIndex: "1" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item>
                        {topbarIsFullWidth ?
                            (
                                <Box sx={{ width: { xs: '100px', md: '650px' }, clipPath: { xs: 'polygon(100% 0, 100% 100%, 0 100%, 35% 0)', md: 'polygon(100% 0, 100% 100%, 0 100%, 5% 0)' } }} className="topbar-buttons-container" />

                            ) : (
                                <Box className="topbar-combined-container" />
                            )}
                    </Grid>
                </Grid>

            </Box>
            <Box className="topbar-fixed" style={{ zIndex: "1" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent={topbarIsFullWidth ? "flex-end" : "space-between"}
                    alignItems="top">
                    {!topbarIsFullWidth && (
                        <Grid item >
                            <Box className="contactbutton">
                                <a style={{ color: "white", textDecoration: "none", fontWeight: "600", fontSize: "20px", fontFamily: "Arial Narrow" }} href="tel:+48698415001">
                                    <Box >
                                        <LocalPhoneIcon />  (+48) 698 415 001
                                    </Box>
                                </a>
                            </Box>
                           
                           
                        </Grid>
                    )}
                    <Grid item>
                        <NavMenuButtons />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default NavMenu
