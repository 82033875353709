import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box, Typography, Divider, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import { Link } from "react-router-dom";

import { ParallaxBanner, Parallax } from 'react-scroll-parallax';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import Letter from './Letter';
import Footer from './Footer';

import GroupsIcon from '@mui/icons-material/Groups';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    const [currentCount, setCurrentCount] = useState(0)
    const incrementCounter = () => {
        setCurrentCount(currentCount + 1);
    }

    const googleMapsIframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.4398949595484!2d21.84073017766575!3d51.54183227182124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47189c8b58ead951%3A0x5b7b8d5a251dfcfa!2sBorowa%2053A%2C%2024-100%20Borowa!5e0!3m2!1spl!2spl!4v1713533968311!5m2!1spl!2spl" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
    const RenderHTML = (props) => (<Box sx={{width: "80vw"}} dangerouslySetInnerHTML={{ __html: googleMapsIframe }}></Box>)


    return (<>
        <Box className="third-background" sx={{ zIndex: 0 }}>
            <Box m={{ xs: 2, sm: 5, md: 15 }} mt={{ xs: 14, sm: 10, md: 20 }} p={{ xs: 4, sm: 6, md: 12 }} className="translucent-container appear-1">
                <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" spacing={8}>
                    <Grid item>
                        <Typography variant="h4">
                            <Box sx={{ textAlign: "center" }} >
                                {t("contact_title")}
                            </Box>
                        </Typography>
                        <Box mb={5} />
                    </Grid>
                    <Grid item container container
                        spacing={5}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item xs={12} sm={4}>

                            <a style={{ color: "white", textDecoration: "none", fontWeight: "600", fontSize: "20px" }} href="tel:+48606693009">
                                <Box className="contact-tile contact-tile-phone">
                                    <Box className="contact-tile-text">
                                        (+48) 698 415 001
                                    </Box>
                                </Box>
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box className="contact-tile contact-tile-mail">
                                <Box className="contact-tile-text">
                                    <a style={{ color: "white", textDecoration: "none", fontWeight: "600", fontSize: "20px" }} href="mailto: kontakt@aneropsa.pl">kontakt@aneropsa.pl</a>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box className="contact-tile contact-tile-post">
                                <Box className="contact-tile-text">
                                    <a style={{ color: "white", textDecoration: "none", fontWeight: "600", fontSize: "20px" }} href="https://maps.app.goo.gl/npf69VtexGB4QGiz8">
                                        Borowa 53 A
                                    </a>
                                    <br />
                                    <a style={{ color: "white", textDecoration: "none", fontWeight: "600", fontSize: "20px" }} href="https://maps.app.goo.gl/npf69VtexGB4QGiz8">
                                        24-100 Puławy
                                    </a>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            <Box className="bodycustomtextonplex" mt={3}>
                                

                                Zapraszamy Państwa do kontaktu telefonicznego, mailowego bądź umówienia wizyty stacjonarnej. Jesteśmy do Państwa dyspozycji od poniedziałku do piątku.
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item>
                                <RenderHTML />
                       
                    </Grid>
                   
                </Grid>
            </Box>
            <Box mb={4} />
        </Box>
        <Box className="blur-section-borders" />
        <Footer />
    </>
    );
}

export default Contact
