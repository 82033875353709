import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box, Typography, Divider, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';

import ImageGallery from "react-image-gallery";

import Image1 from '../assets/g1.webp'
import Image2 from '../assets/g2.webp'
import Image3 from '../assets/g3.webp'
import Image4 from '../assets/g4.webp'
import Image5 from '../assets/g5.webp'
import Image6 from '../assets/g6.webp'
import Image7 from '../assets/g7.webp'
import Image8 from '../assets/g8.webp'
import Image9 from '../assets/g9.webp'
import Image10 from '../assets/g10.webp'
import Image11 from '../assets/g11.webp'
import Image12 from '../assets/g12.webp'
import Image13 from '../assets/g13.webp'
import Image14 from '../assets/g14.webp'
import Image15 from '../assets/g15.webp'
import Image16 from '../assets/g16.webp'
import Image17 from '../assets/g17.webp'
import Image18 from '../assets/g18.webp'
import Image19 from '../assets/g19.webp'
import Image20 from '../assets/g20.webp'

import Footer from './Footer';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const images = [
    {
        original: Image1,
        thumbnail: Image1,
    },
    {
        original: Image2,
        thumbnail: Image2,
    },
    {
        original: Image3,
        thumbnail: Image3,
    },
    {
        original: Image3,
        thumbnail: Image3,
    },
    {
        original: Image4,
        thumbnail: Image4,
    },
    {
        original: Image5,
        thumbnail: Image5,
    },
    {
        original: Image6,
        thumbnail: Image6,
    },
    {
        original: Image7,
        thumbnail: Image7,
    },
    {
        original: Image8,
        thumbnail: Image8,
    },
    {
        original: Image9,
        thumbnail: Image9,
    },
    {
        original: Image10,
        thumbnail: Image10,
    },
    {
        original: Image11,
        thumbnail: Image11,
    },
    {
        original: Image12,
        thumbnail: Image12,
    },
    {
        original: Image13,
        thumbnail: Image13,
    },
    {
        original: Image14,
        thumbnail: Image14,
    }, {
        original: Image15,
        thumbnail: Image15,
    },
    {
        original: Image16,
        thumbnail: Image16,
    },
    {
        original: Image17,
        thumbnail: Image17,
    },
    {
        original: Image18,
        thumbnail: Image18,
    },
    {
        original: Image19,
        thumbnail: Image19,
    },
    {
        original: Image20,
        thumbnail: Image20,
    },

];




const Gallery = () => {
    const { t } = useTranslation();

    return (<>

        <Box className="second-background" sx={{ zIndex: 0 }}>
            <Box m={{ xs: 2, sm: 5, md: 15 }} mt={{ xs: 14, sm: 10, md: 20 }} p={{ xs: 4, sm: 6, md: 12 }} className="translucent-container">
                <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={4} spacing={8}>
                    <Grid item>
                        <Typography variant="h4">
                            <Box className="Playfair Display SC" sx={{ textAlign: "center" }} mb={-6}>
                                {t("gallery_title")}
                            </Box>
                        </Typography>
                        <Box mb={4} />
                    </Grid>
                    <Grid item>
                        <Box>
                            <ImageGallery items={images} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={4} />
        </Box>
        <Box className="blur-section-borders" />
        <Footer />
    </>
    );
}

export default Gallery
